import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification mdxType="InlineNotification">
      <p>{`Legion Crossplatform UI package is currently available as an NPM package. This package is intended to be used in React Native or React projects.`}</p>
    </InlineNotification>
    <br />
    <h2>{`Prequisites`}</h2>
    <p>{`Already have at least these below:`}</p>
    <ul>
      <li parentName="ul">{`Nodejs v16 or above, if you are using multiple versions of node, we recommend using nvm/volta to manage its version`}</li>
      <li parentName="ul">{`Node Package Manager v8 or above`}</li>
      <li parentName="ul">{`Yarn v1.22.19 or above`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Installation`}</h2>
    <h3><strong parentName="h3">{`Proxy and Authentication`}</strong></h3>
    <p>{`Follow the step `}<a parentName="p" {...{
        "href": "https://legion-dev.vercel.app/contribute-and-request#request-access-design-or-code-library"
      }}>{`here`}</a>{` in order to create your authorization token needed.`}</p>
    <p>{`We will provide you with the API url needed to provide the tokens in your project according to your own DTP/Tribe designer. `}</p>
    <p>{`After that, you can try to install the package.`}</p>
    <h3><strong parentName="h3">{`Install the package`}</strong></h3>
    <p>{`With either one of your package manager.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# npm
npm i @legion-crossplatform/ui

# yarn
yarn add @legion-crossplatform/ui
`}</code></pre>
    <p>{`After that, you can set up your provider. `}</p>
    <h3><strong parentName="h3">{`Setup Provider`}</strong></h3>
    <p>{`An example in App.jsx (react native) used by Tribe :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {
  LegionProvider,
  createTokens,
  defaultConfigParams,
  defaultTokenParams
} from '@legion-crossplatform/ui';
import ThemeTokens from './src/utility/theme/pjs';

function App() {
  const pijarToken = createTokens({
    ...defaultTokenParams,
    custom: ThemeTokens
  });
  const pijarConfig = {
    ...defaultConfigParams,
    tokens: pijarToken
  };
  return (
    <Provider store={store}>
        <LegionProvider config={pijarConfig}>
          ...
        </LegionProvider>
    </Provider>
  );
}
`}</code></pre>
    <p>{`After that, you can use the components in your project.`}</p>
    <h3><strong parentName="h3">{`Using the components`}</strong></h3>
    <p>{`An example of the component used in your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";

<Button variant="softPrimary">Button</Button>
`}</code></pre>
    <p>{`For lists of components available to use, you can check the `}<a parentName="p" {...{
        "href": "/components/overview"
      }}>{`components page`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      